.footer div {
    color: black;
    font-weight: 500;
    text-align: center;
    padding-bottom: 10px;
}

.footer div p {
    font-size: 12px;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .footer div p {
        font-size: 10px;
    }
}

