.pl {
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.pl-texts {
    width: 65%;
}

.pl-title {
    font-size: 50px;
    font-weight: 600;
}

.pl-desc {
    margin: 20px 0px;
}

.pl-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


button {
    border: 1px solid #59b256;
    padding: 15px;
    color: #59b256;
    font-weight: 600;
    cursor: pointer;
    margin: 15px;
    font-size: 15px;
    background-color: #fcfcfd;
}

button:hover {
    background-color: #59b256;
    color: white;
}

@media screen and (max-width: 480px) {
    .pl {
        padding: 10px;
    }
    .pl-texts {
        width: 100%;
    }
    .pl-texts h1 {
        display: none;
    }
    .pl-desc {
        display: none;
    }
    .c-right {
        display: flex;
        justify-content: center;
    }
    button {
        padding: 5px;
    }
}

@media (min-width: 481px) and (max-width: 1440px) {
    .pl {
        padding: 50px 0;
    }
}

