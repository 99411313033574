.portfolio {
    background-color: #fcfcfd;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;
}

.portfolio h1 {
    font-size: 50px;
}

.portfolio ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
}

.portfolio .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.item {
    width: 350px;
    height: 200px;
    border-radius: 20px;
    border: 1px solid rgb(240, 239, 239);
    margin: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: relative;
    transition: all 0.5s ease;
    cursor: pointer;
}

.item h3 {
    position: absolute;
    font-size: 20px;
}

.item img, .item a {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    border-radius: 20px;
}

.item:hover{
    background-color: #59b256;
}

.item img:hover {
    opacity: 0.2;
}

@media screen and (max-width: 480px) {
    .portfolio ul {
        margin: 3px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .portfolio h1 {
        font-size: 25px;
    }
    .portfolio ul .portfolioList {
        font-size: 13px;
        margin: 0 4px;
    }
    .portfolio {
        margin-top: -5rem;
    }
}
@media (min-width: 320px) and (max-width: 400px) {
    .portfolio ul {
        margin: 3px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .portfolio h1 {
        font-size: 25px;
    }
    .portfolio ul .portfolioList {
        font-size: 13px;
        margin: 0 4px;
    }
    .portfolio {
        margin-top: -4rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .portfolio {
        padding-top: 100px;
    }
}