.portfolioList {
    font-size: 20px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
}

.portfolioList.active {
    background-color: #59b256;
    color: white;
}