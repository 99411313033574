.intro {
    display: flex;
    height: 100vh;
}

.intro-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-right {
    flex: 1;
    position: relative;
}

.intro-left-wrapper {
    margin-top: 50px;
    padding: 50px;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mobile {
    max-width: 100px;
    height: 80px; 
    display: absolute;
    margin-right: 0.25rem;
    margin-top: 0.25rem;
}

.photoProfile {
    --border-width: 25px;
    margin-top: 0.5rem;
    position: absolute;
    display: none;
    width: 195px;
    height: 195px;
    border-radius: var(--border-width);
    justify-content: end;
    margin-left: 8.5rem;
    z-index: 2;
}
  
.photoProfile::after {
      position: absolute;
      content: "";
      z-index: -1;
      width: 87px;
      height: 87px;
      background: linear-gradient(
        60deg,
        hsl(224, 85%, 66%),
        hsl(269, 85%, 66%),
        hsl(314, 85%, 66%),
        hsl(359, 85%, 66%),
        hsl(44, 85%, 66%),
        hsl(89, 85%, 66%),
        hsl(134, 85%, 66%),
        hsl(179, 85%, 66%)
      );
      background-size: 300% 300%;
      background-position: 0 50%;
      border-radius: calc(2 * var(--border-width));
      animation: moveGradient 4s alternate infinite;
    }

.introduction {
    font-size: 30px;
    font-weight: 300;
}

.intro-name {
    font-size: 50px;
}

.intro-title {
    height: 50px;
    overflow: hidden;
}

.intro-title-wrapper {
    height: 100%;
    animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
    25% {
        transform: translateY(-50px);
    }
    50% {
        transform: translateY(-100px);
    }
    75% {
        transform: translateY(-150px);
    }
    100% {
        transform: translateY(-200px);
    }
}

.intro-title-item {
    height: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #59b256;
    display: flex;
    align-items: center;
}

.intro-img {
    width: 95%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.intro-bg {
    clip-path: polygon(25% 0%, 100% 0, 100% 50%, 100% 100%, 25% 100%, 0% 50%);
    background-color: #59b256;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    right: 0%;
}

p {
    font-size: 20px;
}

.logo img {
    width: 40px;
    height: 40px;
    margin-top: 40px;
    margin-right: 15px;
    cursor: pointer;
}

.logo {
    display: flex;
    flex-direction: row;
}

.cv {
    color: #59b256;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
}

.download {
    color: #59b256;
    cursor: pointer;
    width: 22px;
    height: 22px;
    padding-left: 5px;
}

@media (max-width: 321px) {
    .introduction {
        font-size: 15px;
    }
    .intro-name {
        font-size: 18px;
        margin-top: -2rem;
    }
    .intro-right {
        display: none;
    }
    .intro-title-item {
        font-size: 15px;
    }
    .intro-desc {
        margin-top: -2rem;
        font-size: 12px;
        margin-bottom: -2rem;
    }
    .logo img{
        width: 13px;
        height: 13px;
    }
    .cv {
        font-size: 13px;
    }
    .download {
        width: 13px;
        height: 13px;
    } 
}

@media (min-width: 322px) and (max-width: 376px) {
    .intro {
        height: 85vh;
        margin-top: 2rem;
        margin-left: -1rem;
    }
    .introduction {
        font-size: 18px;
    }
    .intro-name {
        font-size: 30px;
        margin-top: -2.8rem;
        word-wrap: break-word;
        white-space: normal;
    }
    .intro-img {
        display: none;
    }
    .intro-left-wrapper {
        height: 80vh;
        padding: 25px;
        width: 35vh;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }
    .intro-bg {
        clip-path: polygon(95% 0%, 100% 0, 100% 50%, 100% 100%, 95% 100%, 0% 50%);
        background-color: #59b256;
        width: 30%;
        height: 100%;
        position: absolute;
        top: 0%;
        right: 0%;
    }
    .intro-desc {
        font-size: 14px;
    }
    .intro-title-item {
        font-size: 20px;
    }
    .logo img{
        width: 20px;
        height: 20px;
    }
    .cv {
        font-size: 15px;
    }
    .download {
        width: 17px;
        height: 17px;
    } 
    .photoProfile{
        display: flex;
        margin-left: 7.5rem;   
        margin-top: 0.5rem; 
    }
}

@media (min-width: 377px) and (max-width: 450px) {
    .intro {
        height: 85vh;
        margin-top: 2rem;
    }
    .introduction {
        font-size: 18px;
        margin-bottom: -3.3rem;
    }
    .intro-name {
        font-size: 30px;
        word-wrap: break-word;
        white-space: normal;
    }
    .intro-img {
        display: none;
    }
    .intro-left-wrapper {
        height: 80vh;
        padding: 25px;
        width: 35vh;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }
    .intro-bg {
        clip-path: polygon(95% 0%, 100% 0, 100% 50%, 100% 100%, 95% 100%, 0% 50%);
        background-color: #59b256;
        width: 30%;
        height: 100%;
        position: absolute;
        top: 0%;
        right: 0%;
    }
    .intro-desc {
        font-size: 14px;
    }
    .intro-title-item {
        font-size: 20px;
    }
    .logo img{
        width: 20px;
        height: 20px;
    }
    .cv {
        font-size: 15px;
    }
    .download {
        width: 17px;
        height: 17px;
    } 
    .photoProfile{
        display: flex;
        margin-top: 0.5rem; 
    }
}

@media (min-width: 451px) and (max-width: 835px) {
    .introduction {
        font-size: 30px;
    }
    .intro-name {
        font-size: 35px;
        margin-top: -5rem;
    }
    .intro-img {
        display: none;
    }
    .intro-left-wrapper {
        width: 50vh;
        height: 80vh;
        margin-left: 3rem;
    }
    .intro-bg {
        clip-path: polygon(95% 0%, 100% 0, 100% 50%, 100% 100%, 95% 100%, 0% 50%);
        background-color: #59b256;
        width: 30%;
        height: 100%;
        position: absolute;
        top: 0%;
        right: 0%;
    }
    .intro-desc {
        font-size: 20px;
    }
    .intro-title-item {
        font-size: 30px;
    }
    .logo img{
        width: 27px;
        height: 27px;
    }
    .cv {
        font-size: 23px;
    }
    .download {
        width: 23px;
        height: 23px;
    }   
    .photoProfile {
        margin-left: 25rem;
        display: flex;
    }
}