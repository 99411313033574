.about {
    height: 70vh;
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 70px;
}
p {
    text-align: justify;
}

.h2 {
    text-align: center;
}

.about-left {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
    height: 100%;
    background-image: url("https://potenciarweb.com/wp-content/uploads/2021/04/web-hosting-pro.png");
    background-size: cover;
    opacity: 0.8;
}

.about-right{
    flex: 1;
    margin-top: 8rem;
    padding: 0 25px;
}

.about-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
}

.about-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.about-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-title {
    font-weight: 400;
}

.about-sub {
    margin: 20px 0px;
}

.about-desc {
    font-weight: 300;
}

.about-award {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-award-texts {
    width: 70%;
}

.about-award-title {
    font-weight: bold;
    color:#555;
    margin-bottom: 10px;
}
.skill-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
    padding: 20px;
  }
  
  .skill-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .skill-card img {
    width: 40px; 
    height: 40px; 
    margin-bottom: 10px;
  }
  
  .skill-card p {
    margin: 0;
    font-size: 14px;
  }
  

@media (min-width: 1024px) {
    .css {
        margin-left: 7px;
    }    
}

@media (min-width: 481px) and (max-width: 834px) {
    .about-left {
        display: none;
    }
    .about-right {
        padding: 50px;
        margin-top: 120px;
    }
    .h2 {
        text-align: center;
    }
    .skills {
        width: 95%;
    }
    .about-desc {
        padding-right: 0;
    }
    .react {
        padding-left: 0.2rem;
    }
}

@media (max-width: 321px) {
    .about {
        height: 100vh;
        padding-top: 7rem;
    }
}

@media (max-width: 399px) {
    .about {
        margin-top: 3rem;
    }
    .about-left {
        display: none;
    }
    .about-right {
        padding: 25px;  
    }

    .about-title {
        font-size: 23px;
    }
    .about-sub {
        font-size: 17px;
    }
    .about-desc {
        font-size: 13px;
        padding-right: 0;
    }
    .h2 {
        font-size: 17px;
    }
    .skill img {
        width: 25px;
        height: 25px;
    }
    .skill, .out, .redux  h3 {
        font-size: 0.7em;
        
    }
    .out img {
        margin-top: 15px;
        width: 65px;
        height: 30px;
        margin-left: 0.5rem;
    }
    
    .redux img {
        width: 25px;
        height: 25px;
    }
    .git {
        margin-left: 0.3rem;
    }
    .skills {
        width: 90%;
        justify-content: space-between;
    }

    .react {
        padding-left: 0.2rem;
    }
}

@media (min-width: 400px) and (max-width: 430px) {
    .about {
        margin-top: 1rem;
    }
    .about-left {
        display: none;
    }
    .about-right {
        padding: 25px;  
    }
    .about-title {
        font-size: 23px;
    }
    .about-sub {
        font-size: 17px;
    }
    .about-desc {
        font-size: 13px;
        padding-right: 0;
    }
    .h2 {
        font-size: 17px;
    }
    .skill img {
        width: 25px;
        height: 25px;
    }
    
    .skill h3 {
        font-size: 0.7em;
    }
    .skills {
        width: 90%;
        justify-content: space-between;
    }

    .react {
        padding-left: 0.2rem;
    }
}